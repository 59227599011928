import React, { Fragment, useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Contact from './Contact'
import useWindowSize from '../../hooks/windowSizeHook'
import appMessages from '../../utils/appMessages'
import StyledSectionHeader from './StyledSectionHeader'
import ListInfiniteScroll from '../common/ListInfiniteScroll'
import { ContactsContext } from '../../managers/ContactsManager'

const ContactsList = ({ name, selectContact, selectedUsers }) => {
    const { listMaxHeight, setSiblingHeight } = useWindowSize(108)
    const { contacts, hasMoreContacts, refreshContacts, searchString, resetContacts } = useContext(ContactsContext)
    const [listMessage, setListMessage] = useState(appMessages.contactsPlaceholder)

    const manageListHeight = () => {
        if (selectedUsers.length > 0) {
            setSiblingHeight(260)
        } else {
            setSiblingHeight(118)
        }
    }

    const handleContactClick = (contact) => {
        selectContact(contact)
    }

    useEffect(() => {
        manageListHeight()
    }, [selectedUsers])

    useEffect(() => {
        if (searchString.length < 4) {
            resetContacts()
            setListMessage(appMessages.contactsPlaceholder)
            return
        }

        setListMessage(appMessages.noContactsResultsMessage)
    }, [searchString])

    return (
        <StyledContactListWrapper
            listmaxheight={ listMaxHeight }
            data-testid='contacts-list-wrapper'
        >
            <StyledList contactslength={ contacts.length } subheader={ <li /> }>
                <StyledSectionHeader title={ name } data-testid='contacts-list-header' />
                <ListInfiniteScroll
                    dataLength={ contacts.length }
                    height={ listMaxHeight }
                    endDelimiterHeight={ 32 }
                    hasMore={ hasMoreContacts }
                    next={ () => refreshContacts(selectedUsers, searchString, false) }
                    refreshFunction={ () => refreshContacts(selectedUsers, searchString, true) }
                >
                    <Fragment>
                        { contacts.length === 0 ?
                            <StyledTypography variant='caption' data-testid='no-results-message'>
                                { listMessage }
                            </StyledTypography> :
                            contacts.map(contact =>
                                <div key={ contact.userId } data-testid='contacts-list-wrapper'>
                                    <StyledListItem button
                                        onClick={ () => handleContactClick(contact) }
                                        isblocked={ contact.isBlocked ? 1 : 0 }
                                        data-testid='contact-list-item'
                                    >
                                        <Contact contact={ contact } />
                                        { contact.isBlocked &&
                                            <StyledListItemSecondaryAction className='important' data-testid='participant-role'>
                                                Blocked
                                            </StyledListItemSecondaryAction>
                                        }
                                    </StyledListItem>
                                    <Divider light />
                                </div>
                            ) }
                    </Fragment>
                </ListInfiniteScroll>
            </StyledList>
        </StyledContactListWrapper>
    )
}

const StyledContactListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
`

const StyledList = styled(List)`
    width: 100%;
`

const StyledTypography = styled(Typography)`
    && {
        display: block;
        margin: 20px 0;
        padding: 0 16px;
        text-align: center;
    }
`

const StyledListItem = styled(ListItem)`
    && {
        font-style: ${props => props.isblocked ? 'italic' : 'regular' };
        opacity: ${props => props.isblocked ? 0.5 : 1 };
        padding-left: 16px;
        padding-right: ${props => props.isblocked ? '48px' : '16px' };
        word-break: break-all;
    }
`

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
    && {
        color: #49bedb;
        font-size: 10px;
        right: 16px;
        text-transform: capitalize;
    }

    &.important {
        color: #e76d65;
    }
`

ContactsList.propTypes = {
    initStarted: PropTypes.bool,
    name: PropTypes.string,
    selectContact: PropTypes.func,
    selectedUsers: PropTypes.arrayOf(PropTypes.object)
}

export default ContactsList